//Partial File to be imported in main.scss
//CONSULTATION PAGE

.consultation-page_img-container {
	padding: 45px 10px 40px 10px;
}

.consultation-page_text-container {
	padding: 70px 10px 50px 10px;
}

.consultation-page_hr {
	width: 250px;
	float: left;
}

.shadow_box {
	background: #e0eef1;
	border-radius: 5px;
	padding: 20px;
}

@media (max-width: 768px) {
	.consultation-page_img-container,
	.consultation-page_text-container {
		padding: 30px 10px 30px 10px;
	}
}
