//Partial File to be imported in main.scss
//FOTOER PAGE

$color: #52c8fa;

.footer-page {
	padding-top: 5px;
	height: 30px;
	text-align: center;
	color: $color;
	background-color: #ceecf8 !important;
}

@media (max-width: 576px) {
	.footer-page {
		font-size: 3vw;
	}
}
