//NavBar

$color: #327997;
$color-light: #52c8fa;

.navbar {
	line-height: 0;
	padding: 0 1rem;
}

.navbar-flags_container {
	margin: -3px 5px 0px 15px;
}

.navbar-flags {
	display: block;
	margin: 2px;
	cursor: pointer;
	cursor: hand;
}
.port-navbar.port-default {
	width: 100%;
	z-index: 15;
	.port-navbar {
		&-link {
			line-height: 2.2;
			color: $color;
			font-weight: bold;
			text-transform: uppercase;

			&.active {
				color: #fff;
				background-color: $color-light;
				-webkit-transition: background-color .5s ease-in-out;
				-moz-transition: background-color .5s ease-in-out;
				-o-transition: background-color .5s ease-in-out;
				transition: background-color .5s ease-in-out;
			}

			&:hover,
			&:focus {
				color: #fff;
				background-color: $color-light;
				-webkit-transition: background-color .5s ease-in-out;
				-moz-transition: background-color .5s ease-in-out;
				-o-transition: background-color .5s ease-in-out;
				transition: background-color .5s ease-in-out;
			}
		}
	}
}

.nav_not-fixed {
	position: absolute;
	width: 100%;
}

.nav-fixed {
	position: fixed !important;
}

.nav_fixed-top {
	position: static;
	top: 0;
	width: 100%;
}

.navbar-color_transparency-half {
	backdrop-filter: blur(5px);
	background-color: #CEECF8DB !important;
	-webkit-transition: background-color .5s ease-in-out;
	-moz-transition: background-color .5s ease-in-out;
	-o-transition: background-color .5s ease-in-out;
	transition: background-color .5s ease-in-out;
}

.navbar-color_transparency-full {
	background-color: #ceecf8 !important;
	-webkit-transition: background-color .5s ease-in-out;
	-moz-transition: background-color .5s ease-in-out;
	-o-transition: background-color .5s ease-in-out;
	transition: background-color .5s ease-in-out;
}
button:focus {
	outline: none !important;
}

/* Navbar Hamburger animation */
#close-icon {
	// left: 65px;
	width: 30px;
	height: 35px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	cursor: hand;
}

#close-icon span {
	display: block;
	height: 2px;
	width: 100%;
	background: $color;
	border-radius: 2px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
	margin-top: 5px;
}

/* Close Icon */
#close-icon span:nth-child(1) {
	top: 0px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

#close-icon span:nth-child(2) {
	top: 18px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

#close-icon span:nth-child(3) {
	top: 36px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

#close-icon.open span:nth-child(1) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	top: -5px;
	left: 1px;
	position: absolute;
}

#close-icon.open span:nth-child(2) {
	width: 0%;
	opacity: 0;
}

#close-icon.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: 17px;
	left: 1px;
	position: absolute;
}

@media (max-width: 991px) {
	.line-height {
		line-height: 1 !important;
	}

	.navbar-flags_container {
		width: 77%;
		margin: 0px 5px 0px 5px;
	}
	.navbar-flags {
		margin-right: 4px;
		display: inline;
	}

	.nav-link {
		width: 105%;
		background-color: transparent !important;
	}

	.port-navbar.port-default {
		position: absolute;
		top: 0;
		right: 0%;
		width: 3.6rem !important;
		padding: 5px;
		background-color: transparent !important;
		.port-navbar {
			&-brand {
				font-size: 24px;
			}
			&-link {
				line-height: 0.4;
				&.active {
					color: $color !important;
					background-color: transparent !important;
				}
			}
		}
	}

	.navbar-collapse {
		position: absolute;
		display: block;
		width: 185px;
		right: -215px;
		top: 0px;
		padding: 60px 15px 15px 15px;
		border: solid 1px $color;
		-webkit-transition: all 0.2s ease;
		-moz-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	.navbar-collapse.collapsing {
		display: block;
		height: auto !important;
		margin-right: 50%;
		-webkit-transition: all 0.2s ease;
		-moz-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	.navbar-collapse.show {
		backdrop-filter: blur(5px);
		background-color: #CEECF8DB !important;
		right: 0;
	}
	.navbar-color_transparency-half {
		backdrop-filter: none;
	}

	.navbar-toggler {
		z-index: 1;
	}
}

@media (min-width: 991px) {
	.port-navbar.port-default .port-navbar-link {
		font-size: 0.84vw;
		padding-right: .2rem;
		padding-left: .2rem;
	}
}
@media (min-width: 1200px) {
	.port-navbar.port-default .port-navbar-link {
		font-size: 0.81vw;
		padding-right: .35rem;
		padding-left: .35rem;
	}
}
@media (min-width: 1400px) {
	.port-navbar.port-default .port-navbar-link {
		font-size: 0.70vw;
		padding-right: .5rem;
		padding-left: .5rem;
	}
}
