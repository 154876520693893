//Partial File to be imported in main.scss
//TOPINFO PAGE

.vaccines-title {
	margin-top: 5%;
	font-size: 2.3em;
}

.vaccines-header {
	margin-top: 30px !important;
}

.coivd_main-text {
	padding-left: 30px;
	padding-right: 30px;
}

@media (max-width: 576px) {
	.kids_vaccines {
		font-size: 1.9em;
	}
}
