$color: #327997;

ul {
	list-style-type: disc;
}

.google_map {
	height: 80%;
	top: 20%;
}

.country_list {
	width: fit-content;
}
.country_links {
	&:hover {
		color: #4193b6;
		text-decoration: none;
	}
	display: block;
	font-weight: 700;
	color: $color;
}
.map-selected {
	fill: $color;
}

.map-unselected {
	fill: #b0e5fa;
}

.country-img {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.map-img {
	width: 100%;
	height: 120%;
}

.map-selected:hover,
.map-unselected:hover {
	cursor: pointer;
}

.map-text_africa {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.map-text_asia {
	position: absolute;
	top: 20%;
	left: 67%;
}

.map-text_northamerica {
	width: 5%;
	position: absolute;
	top: 22%;
	left: 8%;
}

.map-text_southamerica {
	width: 5%;
	position: absolute;
	top: 65%;
	left: 20%;
}

.map-text_europe {
	position: absolute;
	top: 17%;
	left: 47%;
}

.map-text_oceania {
	position: absolute;
	top: 76%;
	left: 83%;
}

.map_container {
	position: relative;
	text-align: center;
}
.non-selectable {
	font-weight: bold;
	color: #4cb3df;
	pointer-events: none;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.popover-header {
	background-color: #CEECF8C5;
}
.popover {
	background-color: #fcfdfd;
	border: 1px solid #b0e5fa;
}

@media (max-width: 768px) {
	.google_map {
		height: 100%;
		top: 0%;
	}
	.map-text_ {
		&asia,
		&africa,
		&oceania,
		&europe,
		&northamerica,
		&southamerica {
			font-size: 3vw;
		}
		&northamerica {
			top: 15%;
			left: 4%;
		}
		&southamerica {
			top: 59%;
			left: 15%;
		}
	}
}
